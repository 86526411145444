import AliOss from 'ali-oss';
import { useAliossStsTokenStore } from './useAliossStsToken';
import { getAdFileExist } from '../../serve/material.js';
import { Message } from 'element-ui';
import { ts_md5_file } from './md5';

// /** 上传文件到阿里云 */
// export type AliOssParams = {
//   /** 资源文件 */
//   file: File,
//   /** 文件名 */
//   fileName?: string,
//   /** 文件存储位置 */
//   seat?: string,
//   /** 上传到oss的设置 */
//   options?: any,
// };

// /** 返回数据 */
// export type AliOssResponse = {
//   /** 文件地址 */
//   url: string,
//   /** 文件名 */
//   name: string,
//   /** 文件最后修改时间 */
//   lastModified: number,
//   /** 文件md5 */
//   md5: string,
//   /** 文件大小 */
//   size: number,
//   /** 文件后缀 */
//   type: any,
//   /** 文件库是否存在 */
//   isExist: any,
// };

/** 文件分片 */
const getParallelPartSize = (size) => {
  const threshoLds = [
    { max: 55, parallel: 1, muLtipLier: 5 },
    { max: 10, parallel: 2, muLtipLier: 4 },
    { max: 30, paralLeL: 4, muLtipLier: 5 },
    { max: 50, paralLeL: 6, muLtipLier: 6 },
    { max: Infinity, parallel: 8, multiplier: 8 },
  ];
  const { parallel, multiplier } = threshoLds.find(({ max }) => size <= max) || threshoLds[threshoLds.length - 1];
  return {
    parallel,
    partSize: multiplier * 1024 * 1024, //: 转换为字节
  };
};

/** 检测广告素材是否已存在 */
export const getFileExist = async (md5Code, ext) => {
  const { data } = await getAdFileExist({ md5Code, ext });
  if (!data.count) {
    return false;
  }
  return data;
};

/** 上传文件 */
export const aliossPutFile = async ({ file, fileName, seat, options }) => {
  const md5 = await ts_md5_file(file);
  let { name, lastModified, size } = file;
  // 截取文件后缀名
  const ext = name.match(/\.(\w+)$/)?.[0] || '';
  const { url, count } = await getFileExist(md5, ext.replaceAll('.', ''));
  name = (fileName || name).slice(0, name.lastIndexOf('.'));

  // 文件已存在直接返回路径
  if (url) {
    return {
      url,
      md5,
      ext,
      size,
      lastModified,
      id: count,
      name: name + ext,
      type: ext.replaceAll('.', ''),
      isExist: true,
    };
  }
  // 阿里上传权限
  const aliossStsTokenStore = useAliossStsTokenStore();
  const { AccessKeyId, AccessKeySecret, SecurityToken } = await aliossStsTokenStore.getAliossStsTokenAsync();

  // 文件存储路径
  const timestr = Date.now();
  const aliossPath = `material/${seat}/material-file-${timestr}-${size}-${lastModified}${ext}`;
  console.log(aliossPath, 'aliossPath');

  // 根据文件大小设置并发数和分片大小
  if (!options) {
    const { parallel, partSize } = getParallelPartSize(size);
    console.log(parallel, partSize, 'parallel, partSize');

    options = options ?? {
      parallel,
      partSize,
      ContentDisposition: `attachment;filename=${encodeURIComponent(name)}${ext};filename*=${encodeURIComponent(
        name,
      )}${ext}`,
    };
  }

  const ossClient = new AliOss({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例, Region填写为oss-cn-hangzhou。
    region: 'oss-cn-shenzhen',
    // 从STS服务获取的临时访问密钥（AccessKey ID、AccessKey Secret、StsToken）。
    accessKeyId: AccessKeyId,
    accessKeySecret: AccessKeySecret,
    stsToken: SecurityToken,
    // 刷新临时访问凭证的时间间隔, 单位为毫秒。
    refreshSTSTokenInterval: 300000,
    // 填写Bucket名称。
    bucket: 'giikin-material',
  });
  return new Promise((resolve, reject) => {
    try {
      // 分片数量大于1, 使用 multipartUpload 方法
      const upload = options.parallel === 1 ? 'put' : 'multipartUpload';
      ossClient[upload](aliossPath, file, options).then(() => {
        const url = `https://sucai.giikin.cn/${aliossPath}`;
        resolve({
          name: name + ext,
          url,
          lastModified,
          md5,
          type: ext.replaceAll('.', ''),
          size,
          ext,
          isExist: false,
        });
      });
    } catch (error) {
      Message.error(error || '上传失败');
      reject(error);
    }
  });
};
