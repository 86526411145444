import { GET } from '../utils/request.js';
import { getTpDataSign } from '../utils/rsa.js';
const giamCommon = process.env.VUE_APP_GIAM_Common;

const defaultConfig = {
  baseURL: giamCommon,
  withCredentials: false,
};
export function getMulLogList(params, needSign) {
  let headers = {};
  if (needSign) {
    headers = getTpDataSign(params);
  }
  return GET('/log/selectLogByPage', params, { ...defaultConfig, headers: { ...headers } });
}


