<template>
  <div class="el-upload-material">
    <el-upload
      ref="uploadRef"
      v-model:file-list="fileLists"
      action="#"
      :drag="drag"
      :disabled="disabled"
      :show-file-list="false"
      :limit="limit"
      :multiple="multiple"
      :accept="accept"
      :http-request="httpRequest"
      :on-success="onSuccess"
      :on-error="onError"
      :on-exceed="onExceed"
      :before-upload="beforeUpload"
      :on-change="onChange"
      :on-progress="onProgress"
    >
      <slot name="default"></slot>
      <slot name="tip"></slot>
    </el-upload>
  </div>
</template>

<script>
import { aliossPutFile } from './alioss.js';
import { postSaveAdFile } from '../../serve/material.js';
import { uploadMaterialProps } from './uploadMaterial.js';
export default {
  name: 'GmUploadMaterial',
  props: uploadMaterialProps,
  data() {
    return {
      fileLists: [],
    };
  },
  methods: {
    async httpRequest(options) {
      console.log('uploadMaterial httpRequest', options);
      const { file } = options;
      try {
        this.$showLoading();
        const res = await aliossPutFile({
          file,
          fileName: this.fileName || file.name,
          seat: this.seat,
          options: this.options,
        });
        this.$hideLoading();
        res.index = this.index;
        res.sourceType = 'SELF_MATERIAL'; //资源来源：SELF_MATERIAL 自建素材
        // 文件不在资源库，则需要保存入库
        if (!res.isExist) {
          return await this.saveFileResources(res);
        }

        return res;
      } catch (error) {
        console.log('uploadMaterial httpRequest error', error);
        this.$hideLoading();

        return { status: 'error', name: file.name };
      }
    },
    async saveFileResources(fileInfo) {
      const { url, md5: md5_code, size: file_size, ext, name: file_name } = fileInfo;
      const fileData = { url, md5_code, file_size, ext, file_name, play_time: '' };
      //固定参数 source 来源来源: 1:上传 2:广告 3:工作流 4:三方工作流 5:混剪 6:广告回传 isAgent 是否需要代理下载: 0:否 1:是
      const defaultParams = { source: 2, isAgent: 0 };
      const params = { ...this.saveAdFileParams, ...defaultParams, fileData: [fileData] };
      try {
        const res = await postSaveAdFile(params);
        if (res?.code !== 0) {
          throw new Error(res);
        }
        return { ...fileInfo, id: res.data[0] };
      } catch (error) {
        console.log('uploadMaterial saveFileResources error', error);
        
        return { status: 'error', name: file_name };
      }
    },
  },
};
</script>
<style scoped lang="scss">
:deep(.el-upload-dragger) {
  padding: 10px;
  border: none;
}
.el-upload-preview-dialog {
  :deep(.el-dialog__body) {
    padding: 0;
  }
}

:deep(.el-upload-list__item),
:deep(.el-upload--picture-card) {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss">
.el-upload-material {
  .el-overlay-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-dialog {
    margin: 0;
  }

  .el-dialog__body {
    max-height: calc(100vh - 100px);
    overflow-y: auto;

    video,
    img {
      max-height: calc(100vh - 150px);
    }
  }
}
</style>
