export const PropsType = {
  action: {
    type: String,
    required: true,
  },
  headers: {
    type: Object,
    default() {
      return {};
    },
  },
  API: Function,
  data: Object,
  multiple: Boolean,
  name: {
    type: String,
    default: 'file',
  },
  drag: Boolean,
  dragger: Boolean,
  withCredentials: Boolean,
  showFileList: {
    type: Boolean,
    default: true,
  },
  accept: String,
  type: {
    type: String,
    default: 'select',
  },
  value: {
    type: Array,
    default() {
      return [];
    },
  },
  autoUpload: {
    type: Boolean,
    default: true,
  },
  listType: {
    type: String,
    default: 'text', // text,picture,picture-card
  },
  disabled: Boolean,
  limit: Number,
  // onExceed: Function,
  selectLimit: Function,
  selectImgIndex: Number,
  isShowDelete:{
    type:Boolean,
    default:true
  }
};
