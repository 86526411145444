import request from '../utils/request.js';
const material = process.env.VUE_APP_MATERIAL;
const isLocal = false;
// const isLocal = process.env.VUE_APP_API_ENV === 'development' || process.env.VUE_APP_API_ENV === 'develop';
// const isLocal =
//   (material !== 'https://manage-pre.giikin.com/middle/material' && material !== '/material') ||
//   process.env.VUE_APP_API_ENV === 'development';
//  && material !== '/material'
function getUrl(url, prefix = 'work') {
  console.log(isLocal, material, process.env, 'isLocal, prefix, url');

  return isLocal ? `/${prefix}/${url}?_token=60afd310ed1ddd716b8aa6d15e3f2ac8` : `/open/${url}`;
}
// 广告素材上传入库
export function postSaveAdFile(data) {
  return request({
    baseURL: material,
    // url: '/work/saveAdFile',
    withCredentials: true,
    url: getUrl('saveAdFile'),
    method: 'post',
    data: data,
    notThirdLoginUserId: true,
    notNeedToken: true,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

//生成阿里云sts token
export function getOssAccessToken() {
  return request({
    baseURL: material,
    withCredentials: true,
    // url: '/cross/makeOssAccessToken?_token=88adba1062dc8ab528ba92c06dc5c23b',
    url: getUrl('makeOssAccessToken', 'cross'),
    method: 'post',
    notThirdLoginUserId: true,
    notNeedToken: true,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
//检测广告素材是否已存在
export function getAdFileExist(data) {
  return request({
    baseURL: material,
    withCredentials: true,
    // url: '/work/adFilesExist',
    url: getUrl('adFilesExist'),
    method: 'post',
    data: data,
    notThirdLoginUserId: true,
    notNeedToken: true,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
//用户个人广告素材列表
export function userFileRelateList(data) {
  return request({
    baseURL: material,
    withCredentials: true,
    // url: '/work/userFileRelateList?_token=88adba1062dc8ab528ba92c06dc5c23b',
    url: getUrl('userFileRelateList'),
    method: 'post',
    data: data,
    notThirdLoginUserId: true,
    notNeedToken: true,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
