import { md5 } from 'js-md5';

// export async function ts_md5_file(file) {
//   return (
//     new Promise() <
//     string >
//     ((resolve) => {
//       const reader = new FileReader();
//       console.log(reader, 'reader');

//       reader.onload = async function (e) {
//         const binary = e.target.result;
//         resolve(md5(binary));
//       };
//       reader.readAsArrayBuffer(file);
//     })
//   );
// }
export function ts_md5_file(file) {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided'));
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const arrayBuffer = e.target.result;
        // const wordArray = Array.from(new Uint8Array(arrayBuffer))
        //   ? Array.from(new Uint8Array(arrayBuffer))
        //   : arrayBuffer;
        const md5Hash = md5(arrayBuffer);
        resolve(md5Hash);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };

    reader.readAsArrayBuffer(file);
  });
}
