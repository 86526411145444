import { ref } from '@vue/composition-api';
import { getOssAccessToken } from '../../serve/material.js';
import { createEventHook } from '@vueuse/core';
import Cookies from 'js-cookie';

/**
 * @description 素材上传权限
 * @param {*}
 * @return {*}
 **/

export const useAliossStsTokenStore = () => {
  const isLoading = ref(false);
  const aliossStsTokenResult = createEventHook();

  const setCookie = (data) => {
    if (!data) return;
    const { Expiration } = data;
    const time = new Date(new Date(Expiration ?? 0).getTime() - 600000); //-10秒作为缓冲
    Cookies.set('aliossStsToken', JSON.stringify(data), { expires: time });
  };

  /* 请求临时上传权限 */
  const getAliossStsToken = async () => {
    const aliossStsToken = Cookies.get('aliossStsToken');

    if (aliossStsToken) {
      return JSON.parse(aliossStsToken);
    }
    isLoading.value = true;
    try {
      const data = await getOssAccessToken();
      if (data.code === 0) {
        setCookie(data.data);
        aliossStsTokenResult.trigger(data.data);
      }
      return data.data;
    } catch (error) {
      console.log(error, 'error');
    } finally {
      isLoading.value = false;
    }
  };

  /* 监听获取 alioss sts token 成功 promise版 */
  const onAliossStsTokenPromise = () => {
    return (
      new Promise() <
      GetOssAccessTokenResponseData >
      (async (resolve) => {
        aliossStsTokenResult.on((value) => resolve(value));
      })
    );
  };

  /* 获取 alioss 临时上传 token*/
  const getAliossStsTokenAsync = () => {
    /* 若正在请求请求临时token接口 则其他后进来的等待 */
    if (isLoading.value) {
      return onAliossStsTokenPromise();
    }
    return getAliossStsToken();
  };

  return {
    isLoading,
    getAliossStsTokenAsync,
  };
};
