// 列举 el-upload 所有支持的属性
const uploadProps = {
  headers: {
    type: Object,
    default: () => ({}),
  },
  data: {
    type: Object,
    default: () => ({}),
  },
  name: {
    type: String,
    default: 'file',
  },
  withCredentials: {
    type: Boolean,
    default: false,
  },
  showFileList: {
    type: Boolean,
    default: true,
  },
  drag: {
    type: Boolean,
    default: false,
  },
  accept: {
    type: String,
  },
  fileList: {
    type: Array,
    default: () => [],
  },
  autoUpload: {
    type: Boolean,
    default: true,
  },
  listType: {
    type: String,
    default: 'text',
  },
  limit: {
    type: Number,
  },
  onSuccess: {
    type: Function,
  },
  onError: {
    type: Function,
  },
  onProgress: {
    type: Function,
  },
  onChange: {
    type: Function,
  },
  onExceed: {
    type: Function,
  },
  beforeUpload: {
    type: Function,
  },
  beforeRemove: {
    type: Function,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
};
export const uploadMaterialProps = {
  ...uploadProps,
  index: {
    type: Number,
    default: 0,
  },
  accept: {
    type: String,
    default: '*',
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  seat: {
    // 上传位置
    type: String,
    default: 'default',
  },
  options: {
    // alioss上传配置
    type: Object,
    default: () => {},
  },
  fileName: {
    // 上传文件名
    type: String,
    default: '',
  },
  saveAdFileParams: {
    // 上传文件参数
    type: Object,
    default: () => {},
  },
};
